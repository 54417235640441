import Nav from "./Nav";
import Left from "./Left";
import Right from "./Right";
import Main from "./Main";
export default function App() {
    return(
      <>
      <Nav/>
      <Left/>
      <Right/>
      <Main/>
      </>
    );
}
