import "./Right.css";
export default function Right(){
    return(
        <>
        <div className="Right">
            <div>
                <a href="mailto:trademetrader1137@gmail.com">trademetrader1137@gmail.com</a>
            </div>
        </div>
        </>
    );
}